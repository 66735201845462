.app-header .navbar-brand {
  width: 200px;
}

.navbar {  
  background-color: $paigeWhite!important;
}

.navbar-nav {
  padding-right: 2%;
}
body {
  background-color: $paigeWhite;
}

.app {
  > header {
    &.app-header {
      font-size: 16px;
    }
    .talos-download-pagination,
    .talos-user-dropdown {
      font-size: 20px;
      padding: 5;
    }
  }

  .app-header {
    border-bottom: none;
  }

  #search_bar {
    border-bottom: 0.5px solid #b3b3b3;
  }

  .talos-add-new-workspace {
    display: flex;
  }

  #search_text_dashboard {
    border: none;
    border-bottom: 0.5px solid #b3b3b3;
  }

  .nav-link {
    color: $paigeGrey;
    font-weight: 600;
    &:hover {
      color: #144d49 !important;
      font-weight: bold;
      background: transparent !important;
    }
    &.active {
      color: #144d49 !important;
      background: transparent;
      font-weight: bold;
      i {
        color: #144d49 !important;
      }
    }
  }

  .talos-dashboard {
    .ml-auto {
      .nav-item {
        min-width: 60px;
      }
    }
    .nav-item {
      &:after {
        content: "";
        display: none !important;
      }
    }
  }

  .talos-app-brand {
    width: 55px;
  }

  .talos-user {
    li {
      &:hover {
        color: #61849a;
      }
    }
    a {
      &.nav-link {
        &:hover {
          background-color: transparent !important;
          color: #61849a;
        }
      }
    }
  }
}

.bg-light {
  // background-color: rgba(233, 127, 117, 0.1) !important;
  background-color: rgba(20, 77, 73, 0.1) !important;
  // border: 5px solid rgba(233, 127, 117, 0.4);
}

.bootbox {
  .modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 150px;
    margin: auto;
    height: 125px;
  }
}

.classification-legend {
  .cluster-label-wrapper {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px;
    display: inline-flex;
    .cluster-label-icon-0 {
      background-image: url("/assets/icons/Disease.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      background-color: #ff8fbe;
      border: 1px solid #ff8fbe;
      width: 35px;
    }
    .cluster-label-icon-1 {
      background-image: url("/assets/icons/Medication.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      border: 1px solid #1edd41;
      background-color: #1edd41;
      width: 35px;
    }
    .cluster-label-icon-2 {
      background-image: url("/assets/icons/Condition.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      border: 1px solid #fda91c;
      background-color: #fda91c;
      width: 35px;
    }
    .cluster-label-icon-3 {
      background-image: url("/assets/icons/Procedure.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      border: 1px solid #58c4fe;
      background-color: #58c4fe;
      width: 35px;
    }
    .cluster-label-icon-4 {
      background-image: url("/assets/icons/Date.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      border: 1px solid #eb88fe;
      background-color: #eb88fe;
      width: 35px;
    }

    .cluster-label-icon-5 {
      background-image: url("/assets/icons/Blank_Page.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      border: 1px solid #79ecf1;
      background-color: #79ecf1;
      width: 35px;
    }

    .cluster-label-icon-6 {
      background-image: url("/assets/icons/Duplicates.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      border: 1px solid #e9f33c;
      background-color: #e9f33c;
      width: 35px;
    }

    .cluster-label-icon-7 {
      background-image: url("/assets/icons/Intro_Page.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      border: 1px solid #1cfdb2;
      background-color: #1cfdb2;
      width: 35px;
    }

    .cluster-label-icon-8 {
      background-image: url("/assets/icons/Blood_Test.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      border: 1px solid #d7e460;
      background-color: #d7e460;
      width: 35px;
    }

    .cluster-label-icon-9 {
      background-image: url("/assets/icons/Consultation.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      border: 1px solid #5b58fe;
      background-color: #5b58fe;
      width: 35px;
    }

    .cluster-label-icon-10 {
      background-image: url("/assets/icons/GP_Records.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      border: 1px solid #fecb88;
      background-color: #fecb88;
      width: 35px;
    }

    .cluster-label-icon-11 {
      background-image: url("/assets/icons/Content.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      border: 1px solid #1eddd4;
      background-color: #1eddd4;
      width: 35px;
    }

    .cluster-label-icon-12 {
      background-image: url("/assets/icons/Consent_Form.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 35px;
      background-color: #e1a1fe;
      width: 35px;
    }
    .cluster-label {
      padding: 5px 15px;
    }
  }
}

.talos-open-document-link {
  position: relative;
  height: 40px;
  &:before {
    content: " ";
    display: block;
    background-image: url("/assets/icons/Open_Document.svg");
    background-repeat: no-repeat;
    height: 23px;
    position: absolute;
    left: 7px;
    top: 8px;
    width: 23px;
  }
}

.talos-pagination-title {
  position: relative;
  &:before {
    content: " ";
    display: block;
    background-image: url("/assets/icons/Pagination.svg");
    background-repeat: no-repeat;
    height: 23px;
    position: absolute;
    left: 7px;
    top: 13px;
    width: 23px;
  }
}

.talos-open-document-link .nav-link {
  color: black !important;
}

.Medical_Key_Word_Aside_Menu {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 15px;
  display: inline-flex;
  &.subheading {
    font-size: 14px;
    color: #707070;
  }
  &.label {
    font-size: 14px;
    color: #707070;
    padding-left: 15px;
    padding-top: 15px;
  }
  &.Disease_Icon {
    // background-image: url("/assets/icons/Disease.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    background-color: #e6194b;
    border: 1px solid #e6194b;

    width: 35px;
  }
  &.Medication_Icon {
    // background-image: url("/assets/icons/Medication.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #3cb44b;
    background-color: #3cb44b;
    width: 35px;
  }
  &.Condition_Icon {
    // background-image: url("/assets/icons/Condition.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #42d4f4;
    background-color: #42d4f4;
    width: 35px;
  }
  &.Procedure_Icon {
    // background-image: url("/assets/icons/Procedure.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #469990;
    background-color: #469990;
    width: 35px;
  }
  &.Date_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid $paigeBlack;
    background-color: $paigeBlack;
    width: 35px;
  }
  &.Org_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #ffe119;
    background-color: #ffe119;
    width: 35px;
  }
  &.Person_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #800000;
    background-color: #800000;
    width: 35px;
  }
  &.Time_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #f58231;
    background-color: #f58231;
    width: 35px;
  }
  &.Quantity_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #bfef45;
    background-color: #bfef45;
    width: 35px;
  }
  &.Ordinal_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #9a6324;
    background-color: #9a6324;
    width: 35px;
  }
  &.GPE_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #808000;
    background-color: #808000;
    width: 35px;
  }
  &.Product_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #4363d8;
    background-color: #4363d8;
    width: 35px;
  }
  &.FAC_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #911eb4;
    background-color: #911eb4;
    width: 35px;
  }
  &.Lang_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #f032e6;
    background-color: #f032e6;
    width: 35px;
  }
  &.NORP_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #fabed4;
    background-color: #fabed4;
    width: 35px;
  }
  &.Money_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #ffd8b1;
    background-color: #ffd8b1;
    width: 35px;
  }
  &.Percent_Icon {
    // background-image: url("/assets/icons/Date.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #aaffc3;
    background-color: #aaffc3;
    width: 35px;
  }

  &.Blank_Page_Icon {
    background-image: url("/assets/icons/Blank_Page.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #79ecf1;
    background-color: #79ecf1;
    width: 35px;
  }

  &.Duplicates_Icon {
    background-image: url("/assets/icons/Duplicates.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #e9f33c;
    background-color: #e9f33c;
    width: 35px;
  }

  &.Intro_Icon {
    background-image: url("/assets/icons/Intro_Page.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #fda91c;
    background-color: #fda91c;
    width: 35px;
  }

  &.Blood_Test_Icon {
    background-image: url("/assets/icons/Blood_Test.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #ff8fbe;
    background-color: #ff8fbe;
    width: 35px;
  }

  &.Consultation_Icon {
    background-image: url("/assets/icons/Consultation.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #58c4fe;
    background-color: #58c4fe;
    width: 35px;
  }

  &.GP_Icon {
    background-image: url("/assets/icons/GP_Records.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #eb88fe;
    background-color: #eb88fe;
    width: 35px;
  }

  &.Content_Icon {
    background-image: url("/assets/icons/Content.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    border: 1px solid #1edd41;
    background-color: #1edd41;
    width: 35px;
  }

  &.Consent_Form_Icon {
    background-image: url("/assets/icons/Consent_Form.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35px;
    background-color: #a1a6fe;
    width: 35px;
  }

  &.Consent_Form_1 {
    background-image: url("/assets/icons/arrow_right.svg");
    background-repeat: no-repeat;
    transform: rotate(180deg);
    height: 15px;
    width: 15px;
  }
  &.Consent_Form_2 {
    font-size: 12px;
    color: #707070;
    padding-right: 24px;
    padding-left: 24px;
  }
  &.Consent_Form_3 {
    background-image: url("/assets/icons/arrow_right.svg");
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
    background-position-y: 93%;
  }
  &.Text {
    padding-left: 15px;
    padding-top: 5px;
    color: #707070;
  }
  &.Text2 {
    padding-left: 15px;
    padding-top: 5px;
    color: #707070;
    font-size: 14px;
  }
}

.Consent_Form_Text {
  position: absolute;
  left: 31%;
  bottom: -7%;
}

.Medical_Key_Word_Aside_Menu_Div {
  padding-bottom: 15px;
}

.cluster-legend,
.classification-legend {
  border-bottom: 0.5px solid $paigeGrey;
}

.undo {
  background-image: url("/assets/icons/Rotate_Counterclockwise.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 25px;
  width: 25px;
}

.redo {
  background-image: url("/assets/icons/Rotate_Clockwise.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 25px;
  width: 25px;
  padding-left: 35px;
  padding-right: 35px;
}

.extract {
  background-image: url("/assets/icons/Extract_Page.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 25px;
  width: 30px;
}

.Organise_Pages {
  display: flex;
  border-bottom: 0.5px solid #707070;
  // padding-top: 15px;
  padding-bottom: 15px;
}

.Organise_Pages_Text {
  padding-left: 5px;
  padding-top: 4px;
  font-size: 12px;
  color: #707070;
}

.Organise_Pages_Header {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 15px;
  &.Reorder,
  &.Classification {
    color: #707070;
    padding-top: 15px;
  }
}

.order {
  background-image: url("/assets/icons/cil-sort-numeric-down.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 25px;
  width: 30px;
}

.by_date {
  background-image: url("/assets/icons/Icon_reorder_Date_down.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 25px;
  width: 30px;
}

.order_text,
.by_date_text {
  display: inline-flex;
  padding-bottom: 15px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #707070;
  &.Text {
    padding-left: 5px;
    padding-top: 5px;
  }
}

.btn-fle-primary {
  background: $paigePrimary;
  color: $paigeWhite;
  cursor: pointer;
  &:hover {
    color: $paigeWhite !important;
    background: #d5b75c !important;
  }
}

.btn-fle-secondary {
  background: #a1a6fe;
  color: $paigeWhite;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}

.btn-fle-primary {
  /* Ripple effect */
  &.ripple {
    background-position: center;
    transition: background 0.8s;
  }
  &.ripple:hover {
    background: $paigePrimary radial-gradient(circle, transparent 1%, $paigePrimary 1%)
      center/15000%;
  }
  &.ripple:active {
    background-color: rgba(18, 76, 72, 0.4);
    background-size: 100%;
    transition: background 0s;
  }
}

.btn-secondary {
  /* Ripple effect */
  &.ripple {
    background-position: center;
    transition: background 0.8s;
  }
  &.ripple:hover {
    background: #c8ced3 radial-gradient(circle, transparent 1%, #c8ced3 1%)
      center/15000%;
  }
  &.ripple:active {
    background-color: $paigeWhite;
    background-size: 100%;
    transition: background 0s;
  }
}

/* Ripple effect */
.rippling {
  transition: background 0.8s;
  background: rgba(18, 76, 72, 0.1)
    radial-gradient(circle, transparent 1%, rgba(18, 76, 72, 0.1) 1%)
    center/15000%;
  background-color: rgba(18, 76, 72, 0.1);
  background-size: 100%;
  color: $paigeWhite;
}

.login-spinner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  .login-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    &.text-info {
      color: #144d49 !important;
    }
  }
  p {
    color: $paigeWhite !important;

    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 10px;
    width: 366px;
    font-weight: bold;
    font-size: 22px;
  }
}

.ps__rail-x,
.ps__rail-y {
  display: none !important;
}

.header-fixed {
  #root {
    .app-header {
      border-bottom: 1px #ddd solid;
    }
  }
}

.pacer-bar {
  height: 3px;
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  z-index: 1000000;
}

.fv__search-panel-form-container .fv__icon-sidebar-search {
  width: 30px;
  height: 30px;
  position: absolute;
  background-size: 50%;
  right: 3px;
}

.accordion {
  .card {
    margin-bottom: 0;
    .card-header {
      button {
        color: $paigeBlack !important;
      }
    }
    .card-body {
      background-color: $paigePrimary;
      color: $paigeWhite;
      a {
        color: $paigeWhite;
      }
    }
  }
}
.talos-heading {
  font-size: 16px !important;
  color: $paigeBlack;
  font-weight: 400 !important;
  margin-bottom: 0;
}
.talos-pdf-page-count {
  width: 120px !important;
}

.workspace-modal-error {
  color: #881717 !important;
  font-weight: bold;
}

#loadMore {
  text-align: center;
}

.bates-numbering {
  color: darkgray;
  height: 37px;
  padding-left: 35px;
  padding-right: 20px;
  font-size: smaller;
}

.left {
  float: left;
}

.right {
  float: right;
}
.no-document {
  text-align: center;
  margin: auto;
  height: 25px;
  position: absolute;
  top: 0;
  left: 110px;
  right: 0;
  bottom: 0;
  width: 275px;
  font-weight: bold;
}

.fv__ui-toolbar .fv__ui-dropdown.selected,
.fv__ui-toolbar .fv__ui-dropdown:not(.disalbed):hover {
  border-color: rgba(18, 76, 72, 0.5) !important;
  box-sizing: border-box;
}

.fv__ui-toolbar .fv__ui-dropdown-toggler.active,
.fv__ui-toolbar .fv__ui-dropdown.selected .fv__ui-dropdown-icon,
.fv__ui-toolbar .fv__ui-dropdown:not(.disalbed):hover .fv__ui-dropdown-icon {
  background-color: rgba(18, 76, 72, 0.3) !important;
}

.fv__ui-button:not(.disabled):hover {
  background-color: rgba(18, 76, 72, 0.3) !important;
}

.btn-danger {
  border-color: #881717 !important;
  background-color: #881717 !important;
  &:hover {
    color: $paigeWhite;
    background-color: #c69e22 !important;
    border-color: #b99320 !important;
  }
}

.btn-primary,
.btn-success {
  border-color: #0f413d !important;
  background-color: $paigePrimary !important;
  &:hover {
    color: $paigeWhite;
    background-color: #c69e22 !important;
    border-color: #b99320 !important;
  }
}

#pdf-being-processed-container,
#processed-pdfs {
  .btn-danger {
    border-color: #0f413d !important;
    background-color: $paigePrimary !important;
    &:hover {
      color: $paigeWhite;
      background-color: #c69e22 !important;
      border-color: #b99320 !important;
    }
  }
}
