@import "all";
@import "foxit";
@import "dashboard";
@import "login";
@import "workspace";
@import "help";

.header-fixed {
  .app-body {
    margin-top: 88px !important;
  }

  #root {
    .app-header {
      flex: 0 0 88px;
      height: 88px;
      font-size: 20px;
      .talos-user {
        span {
          font-weight: 400;
          font-size: 14px;
          padding-right: 24px;
        }
      }
      .user-icon-svg {
        position: relative;
        top: -2px;
      }
    }

    .navbar-brand {

      margin-left: 0px;
      font-weight: 300;
      img {
        width: 130px;
        margin-left: 0;
      }
    }

    .talos-workspace {
      .navbar-brand {
        img {
          width: 130px;
          margin-left: 0;
        }
      }
    }

    .talos-text-brand {
      margin-left: 17px;
      position: relative;
      font-weight: 300;
      top: 7px;
    }
  }
}

.talos-dashboard, .talos-help, .talos-settings, .talos-account, .talos-about {
  .app-body {
    margin-top: 88px;
    .sidebar_home_icon {
      background-image: url("/assets/icons/cil-home.svg");
      background-repeat: no-repeat;
      background-position: 27px 8px;
      
    }
    .sidebar_user_icon {
      background-image: url("/assets/icons/cil-user.svg");
      background-repeat: no-repeat;
      background-position: 27px 8px;
      
    }
    .sidebar_help_icon {
      background-image: url(/assets/icons/cil-school.svg);
      background-repeat: no-repeat;
      background-position: 27px 8px;
    }
    .sidebar_settings_icon {
      background-image: url("/assets/icons/cil-settings.svg");
      background-repeat: no-repeat;
      background-position: 27px 8px;
    }

    .sidebar_nonsearchable_icon {
          background: url('/assets/icons/cil-x.svg');
          background-repeat: no-repeat;
          background-position: 35% 50%;
    }
    
    .sidebar_about_icon {
      background-image: url("/assets/icons/cil-globe-alt.svg");
      background-repeat: no-repeat;
      background-position: 27px 8px;
    }
    .nav-link {
      margin-left: 27px;
      font-size: 14px;
      font-weight: 500;
    }
    .sidebar_logout_icon {
      &:before {
        display: none;
      }
      background-repeat: no-repeat;
      background-position: 27px 8px;
    }
  }
  .sidebar {
    .nav-link {
      font-size: 14px !important;
      line-height: 17px;
      padding: 11px 1rem;
    }
  }
  #talos-recent-workspaces, #talos-shared-workspaces {
    tr {
      th,
      td {
        padding-bottom: 11px !important;
      }
    }
  }
  .dashboard-widgets {
    position: relative;
    height: 192px;
    margin-bottom: 39px;
    .talos-add-new-workspace {
      width: 32%;
      display: inline-block;
      // background-color: rgba(233, 127, 117, 0.1) !important;
      background-color: rgba(20,77,73, 0.1) !important;
      // border: 5px solid rgba(233, 127, 117, 0.4);
   
      height: 192px;
      position: absolute;
      top: 0;
      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        height: 20px;
        width: 124px;
      }
    }
    #talos-dropzone-container, #talos-mergezone-container  {
      width: 33%;
      display: inline-block;
      height: 192px;
      position: absolute;
      left: 33%;
      #talos-dropzone, #talos-mergezone {
        margin-top: 0 !important;
        height: 192px;
        padding-top: 33px;
      }
    }
    #talos-dropzone-container {      
      left: 67%;
    }
    #talos-mergezone-container {      
      svg {
        width: 44px;
      }
    }

  }
}
#dropzone-previews {
  margin-bottom: 40px !important;
  color: $paigeGrey;
  .talos-pdf-being-processed {
    border-left: 0;
    border-top: 0;
    border-right: 0;
  }
  .dz-filename {
    padding-left: 0 !important;
    span {
      padding-left: 20px;
    }
  }
  .talos-pdf-processing-process {
    text-align: right;
  }

  .rw-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      input {
        ~ .rw-checkmark {
          background-color: #ccc;
        }
      }
    }
    .input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked {
        ~ .rw-checkmark {
          background-color: #2196f3;
          &:after {
            display: block;
          }
        }
      }
    }
    .rw-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}
#pdf-being-processed-container {
  p {
    padding-bottom: 14px;
    padding-top: 0px !important;
  }
}

.recent-workspaces-label, .shared-workspaces-label  {
  position: absolute;
  top: -15px;
}
.recent-workspaces-label, .shared-workspaces-label ,
#pdf-being-processed-container p {

  font-size: 16px !important;
  color: $paigeBlack;
  font-weight: 400 !important;
  margin-bottom: 0;
}
.rw-label {
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 400;
  padding-bottom: 11px !important;
}
.rw-label,
.rw-data {
  color: $paigeGrey;
  font-size: 12px;
  font-weight: 400;
}
.rw-data {
  font-size: 14px;
}

.pmr-create-workspace-link {
  background: #144d49;
  text-align: right;
  text-transform: uppercase;
  color: $paigeWhite;
  margin-bottom: 15px !important;
  letter-spacing: 1.25px;
  position: relative;
  top: 20px;
  font-weight: bold;
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
}

#dropzone-previews {
  .talos-pdf-being-processed {
    padding: 0 !important;
  }
  .talos-pdf-processing-process {
    text-align: right;
  }

  .pmr-checkbox {
    width: 20px !important;
    max-width: 20px !important;
  }

  .custom-control {
    top: -2px;
  }

  .custom-control-input {
    z-index: 1000;
    width: 20px !important;
    height: 20px !important;
    position: absolute !important;
    left: 0 !important;
    top: -2px;
  }

  .custom-control-label {
    color: $paigeBlack;
    &:before {
      width: 20px !important;
      height: 20px !important;
      color: $paigeBlack;
    }
    &:after {
      width: 20px !important;
      height: 20px !important;
      color: $paigeBlack;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: rgba(233, 127, 117, 0.2);
    background-color: rgba(198, 158, 35, 0.2);
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='grey' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }

  .talos-pdf-processing-process,
  .dz-size,
  .dz-filename,
  .dz-progress {
    line-height: 22px;
  }
  .progress {
    position: relative;
    top: 8px;
    height: 5px;
    .progress-bar {
      height: 5px;
      background-color: rgba(233, 127, 117, 1)!important; 
      &.bg-success {
        background-color: rgba(233, 127, 117, 1)!important; 
    }
    }
  }
}
