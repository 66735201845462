.aps-notifications {
  a {
    width: 27px;
    svg {
      width: 27px;
      path {
        fill: $paigeBlack;
      }
    }
  }
  .clear-notifications {
    position: sticky;
    width: 100%;
    text-align: center;
    padding: 5px;
    background: #efefef;
    font-weight: bold;
    color: $paigeBlack;
    bottom:-1px;
    &:hover {
      background: #e0e0e0;
      cursor: pointer;
    }
  }
}

.app-header .nav-item {
  min-width: 50px;
  text-align: left;
}

.icon-cloud-download {
  color: $paigeBlack;
  font-weight: bold;
  font-size: 24px;
  top: 4px;
  position: relative;
  right: 5px;
}
