$paigePrimary: #124c48;
$paigeSecondary: #c69e23;
$paigeBlue: #1a5d94;
$paigeRed: #c62323;
$paigeYellow: #c69e23;
$paigeGreen: #124c48;
$paigeWhite: #fcfcfc;
$paigeBlack: #222;
$paigeLightGreen: rgba(18, 76, 72, 0.5);
$paigeGreen2: #144d49;
$paigeGrey: #999;
