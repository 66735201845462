@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url(../../fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans";
  font-weight: 100;
  src: local("OpenSans"), url(../../fonts/OpenSans-Light.ttf) format("truetype");
}


@font-face {
    font-family: "NunitoSans";
    font-weight: 100;
    src: local("NunitoSans"),
      url(../../fonts/NunitoSans-ExtraLight.ttf) format("truetype");
  }

@font-face {
  font-family: "NunitoSans";
  font-weight: 200;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 300;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 400;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 500;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 600;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 700;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-Black.ttf) format("truetype");
}


@font-face {
    font-family: "NunitoSans";
    font-weight: 100;
    font-style: italic;
    src: local("NunitoSans"),
      url(../../fonts/NunitoSans-ExtraLightItalic.ttf) format("truetype");
  }

@font-face {
  font-family: "NunitoSans";
  font-weight: 200;
  font-style: italic;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 300;
  font-style: italic;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 400;
  font-style: italic;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 500;
  font-style: italic;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 600;
  font-style: italic;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  font-weight: 700;
  font-style: italic;
  src: local("NunitoSans"),
    url(../../fonts/NunitoSans-BlackItalic.ttf) format("truetype");
}

body,
.font-opensans {
  font-family: "NunitoSans", sans-serif !important;
}
.sidebar {
  background: white;
  .nav-title {
    text-transform: capitalize !important;
    font-size: 14px !important;
  }

  .nav-link {
    .nav-icon {
      font-size: 1.5rem !important;
    }
  }
}
