.aps-new-workspace {
    // background: transparent!important;
    // border: 4px solid rgba(122, 160, 203, 0.5)!important;
  // border: 4px solid rgba(255, 105, 97, 0.5)!important;
}

.aps-dropzone {
    // background: transparent!important;
//   border: 4px solid rgba(119, 221, 119, 0.5)!important;
  // border: 4px solid rgba(255, 105, 97, 0.5)!important;
}

.aps-mergezone {
    // background: transparent!important;
  // border: 4px solid rgba(255, 105, 97, 0.5)!important;
}


.btn-danger {
    color: $paigeWhite;
    background-color: #d5b75c;
    // border-color: #d5b75c;
}