.login {
  position: relative;
  width:100vw;
  height: 100vh;
  overflow: hidden;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    background-image:url(/assets/img/backgrounds/dukeshouse.jpg);
    background-size: cover;
    top: 0;
    left:0;
    width:100vw;
    height: 100vh;
    background-position-x: -200px ;
    opacity:0.5;

  }
  
  #adfs-demo {
    margin-bottom: 1.5rem;
      position: absolute;
      right: 0;
      width: 25vw;
      height: 100vh;
      h1 {
        margin-bottom: 110px;
      }
  }

  .login-spinner-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0px;
    left: 0px;
    .login-spinner {
      position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    }
  }
}