.page-workspace {
  overflow: hidden !important;
  .talos-workspace-link {
    color: $paigeBlack;
    width: 100%;
    display: block;
    @include transition(all 0.3s ease);
    &:hover {
      text-decoration: none;
      color: $paigeBlack;
      @include material-shadow($z-depth: 2);
      @include transition(all 0.3s ease);
    }
  }

  .talos-pdf-upload {
    width: 200px;
    margin: auto;
  }

  .talos-close-operating-tools {
    position: absolute;
    top: -20px;
    right: 3px;
    z-index: 1;
    font-weight: 100;
    cursor: pointer;
    font-size: 40px;
  }

  .changes-saved {
    font-size: 18px;
    opacity: 0.66 !important;
    font-weight: bold;
    color: white !important;
    padding-right: 16px;
    padding-left: 16px;
    position: absolute;
    right: 120px;
    background-color: $paigePrimary;
  }

  .changes-saving {
    font-size: 18px;
    opacity: 0.66 !important;
    font-weight: bold;
    color: black !important;
    padding-right: 16px;
    padding-left: 16px;
    position: absolute;
    right: 120px;
    background-color: #d5b75c;
  }

  .aside-menu {
    .nav {
      &.nav-tabs {
        @include material-shadow(1, 1, $paigeBlack);
        .talos-operating-tool-tab {
          transition: all 0.2s;
          &:hover {
            background-color: #144d49 !important;
            transition: all 0.2s;
            @include material-shadow(2, 0.25, $paigeBlack);
            i {
              transition: all 0.2s;
              filter: invert(100%);
            }
          }
          a {
            transition: all 0.2s;
            &:hover {
              transition: all 0.2s;
              background-color: #144d49 !important;
              @include material-shadow(2, 0.25, $paigeBlack);
              svg {
                fill: $paigeWhite;
              }
              i {
                filter: invert(100%);
                transition: all 0.2s;
              }
            }
          }
          .nav-link {
            height: 55px;
            overflow: hidden;
            i {
              position: relative;
              top: -9px;

              &.icon-question {
                position: relative;
                top: 4px;
                left: 8px;
              }
              &.fa {
                position: relative;
                top: 3px;
                left: 3px;
                font-size: 25px;
              }
            }
          }
        }
      }
    }
  }
  .sidebar {
    font-size: 12px !important;
    a {
      font-size: 12px !important;
    }
    .hr {
      height: 0.5px;
      background: #ccc;
      width: 80%;
      margin: 8px auto;
      &:after {
        content: "" !important;
      }
    }
    .basket-set {
      &:after {
        content: "" !important;
      }
    }
    .nav-dropdown-items {
      .basket-item {
        a {
          padding-left: 35px !important;
          padding-right: 30px;
        }
      }
    }
    .nav-dropdown-toggle {
      &:before {
        background-image: url("/assets/icons/Arrow.svg") !important;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        left: 1rem;
        right: auto;
        transition: transform 0.6s !important;
      }
    }
  }

  .sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
    transform: rotate(0deg) !important;
  }
  .talos-add-document-modal {
    .workspace-dropzone {
      .talos-pdf-being-processed {
        background-color: $paigeWhite;
        .progress {
          margin-top: 4px;
        }
      }
    }
    .pmr-create-workspace-link {
      float: right;
      width: auto !important;
      padding: 5px;
      top: -38px;
      border-radius: 5px;
      font-size: 14px;
      span {
        margin-left: 10px;
      }
    }
    .workspace-pdfs-processed {
      margin-top: 4px;
      height: 50vh;
      overflow-y: scroll;
      .talos-pdf-processed {
        background-color: $paigeWhite;
        padding: 10px;
        border: 1px solid #ccc;
        margin: 10px 0 !important;
        cursor: pointer;
      }
    }
  }

  .talos-rename-modal {
    display: block;
  }

  .nav {
    .navigation-item-menu {
      display: block !important;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 10px;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      color: #73818f;
    }
  }
  .sidebar {
    border-right: 1px #ddd solid;
    .nav-item.open {
      border-right: 1px #ddd solid;
    }
    .ps {
      overflow: visible !important;
    }
    .nav-item {
      .nav-dropdown-items {
        overflow-y: visible !important;
        display: none !important;
      }
      &.open {
        background: white;
        .nav-dropdown-items {
          overflow-y: visible !important;
          display: block !important;
        }
      }
    }
  }

  .basket-item a {
    color: black !important;
  }

  .workspaces-spinner-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #ccc;

    background: linear-gradient(
      90deg,
      #aaa,
      #bbb,
      #ccc,
      #ddd,
      #eee,
      $paigeWhite
    );
    background-size: 200% 200%;
    animation: gradient 15s ease infinite;

    top: 0px;
    left: 0px;
    z-index: 2000;
    opacity: 0.8;
    .workspaces-spinner {
      position: absolute;
      top: -75px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    .workspaces-spinner-loading-text {
      height: 100vh;
      width: 100vw !important;
      color: #222 !important;
      font-size: 30px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      justify-content: center;
    }
  }

  .atlwdg-trigger {
    z-index: 3000;
    .atlwdg-SUBTLE {
      z-index: 3000;
    }
  }

  .talos-download-pagination-popup {
    .input[type="text"],
    textarea {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      outline: none;
      padding: 3px 0px 3px 3px;
      margin: 5px 1px 3px 0px;
      border: 1px solid #dddddd;
    }
    &.error {
      input[type="text"]:focus,
      textarea:focus {
        box-shadow: 0 0 5px rgb(221, 47, 41);
        padding: 3px 0px 3px 3px;
        margin: 5px 1px 3px 0px;
        border: 1px solid rgb(221, 47, 41);
      }
    }
  }

  .talos-download-pagination-popup {
    position: fixed;
    top: 10px;
    left: 0;
    right: 0;
    margin: auto;
    width: 90vw;
    height: 95vh;
    .modal-header {
    }
    &.bootbox .modal-dialog {
      position: relative !important;
      min-height: 95vh !important;
      min-width: 90vw !important;
      height: auto !important;
      bottom: auto !important;
      right: auto !important;
    }
    &.bootbox .modal-dialog-centered {
      min-height: 95vh !important;
      min-width: 90vw !important;
    }
    .bootbox-body {
      height: 65vh;
      max-height: 65vh;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
    }
  }

  .fv__icon-sidebar-search {
    background-image: url("/assets/icons/cil-magnifying-glass.svg") !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .highlight_icon {
    background-image: url("/assets/icons/cil-highlighter.svg") !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .share_icon {
    background-image: url("/assets/icons/cil-share.svg") !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: 0.8;
    background-size: 70%;
  }

  .organize_page_icon {
    background-image: url("/assets/icons/organise_Page.svg") !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .cli-book {
    background-image: url("/assets/icons/cil-book.svg") !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .close_icon {
    background-image: url("/assets/icons/cil-x.svg") !important;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    position: absolute;
    top: 15px;
    right: 3px;
    z-index: 1;
  }

  #pdf-ui {
    // overflow: visible;
    .fv__ui-webpdf {
      // overflow: visible;
      .fv__ui-body {
        // overflow: visible;
      }
    }
  }

  .sidebar {
    #workspace-nav-scrollbar {
      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      &::-webkit-scrollbar-thumb {
        background: #e1e1e1;
        border: 0px none $paigeBlack;
        border-radius: 50px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: $paigeWhite;
      }
      &::-webkit-scrollbar-thumb:active {
        background: $paigeBlack;
      }
      &::-webkit-scrollbar-track {
        background: #666666;
        border: 0px none #383838;
        border-radius: 50px;
      }
      &::-webkit-scrollbar-track:hover {
        background: #666666;
      }
      &::-webkit-scrollbar-track:active {
        background: $paigeBlack;
      }
      &::-webkit-scrollbar-corner {
        background: transparent;
      }
    }
    .ps {
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }
  }

  .fv__ui-thumbnail-container
    .fv__thumb-image-container
    .fv__thumb-page
    .fv__thumb-image {
    background: #fefefe;
  }
}

//Workspace
.page-workspace {
  body,
  #root {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
    // overflow-y: hidden;

    .sidebar {
      .active-basket {
        border-left: 4px solid rgba(18, 76, 72, 0.6);
        border-right: 1px solid rgba(18, 76, 72, 0.6);
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-right: 7px solid rgba(18, 76, 72, 0.6);
          position: fixed;
          left: 199px;
          transform: rotate(180deg);
          margin-top: -27px;
        }
        a {
          padding-left: 29px !important;
          color: #144d49 !important;
        }
      }
      .active-document {
        border-left: 4px solid rgba(18, 76, 72, 0.6);
        border-right: 1px solid rgba(18, 76, 72, 0.6);
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-right: 7px solid rgba(18, 76, 72, 0.6);
          position: fixed;
          left: 199px;
          transform: rotate(180deg);
          margin-top: -27px;
        }
        a {
          padding-left: 29px !important;
          color: #144d49 !important;
        }
      }
      .nav-item {
        &.basket-item,
        &.document-item {
          font-size: 14px;
          i {
            display: none;
          }
          a {
            padding-left: 35px;
            padding-right: 30px;
          }
        }

        &.document-item {
          & > span {
            margin-top: 5px;
            margin-bottom: 5px;
            position: relative;
            top: 0px;
            padding: 5px;
          }
        }
        .basket-set {
          padding-right: 31px;
          color: black;
        }
        &.title {
          text-transform: capitalize;
          font-size: 14px;
          padding: 0.3rem 0rem;
          font-weight: 700;
          color: #e4e7ea;
          background: transparent;
          &.active {
            background: transparent;
          }
          .nav-link:hover {
            color: $paigeWhite;
            background: transparent;
          }
          a {
            padding: 0.7rem 1rem;
            background: transparent;
          }
          i.nav-icon {
            display: inline;
            color: $paigeWhite;
            font-size: 14px !important;
          }
        }
      }

      .nav-item {
        // &:after {
        //   content: "...";
        //   display: block !important;
        //   width: 20px;
        //   height: 20px;
        //   text-align: center;
        //   line-height: 10px;
        //   position: absolute;
        //   top: 15px;
        //   right: 15px;
        //   cursor: pointer;
        //   color: #73818f;
        // }
        // .hover {
        //   &:after {
        //     color: $paigeWhite !important; //Ready for when clicing on dots etc.
        //   }
        // }
        &.document-item,
        &.basket-item {
          .nav-link {
            &:hover {
              color: $paigeWhite;
              background: transparent !important;
            }
            &.active {
              color: $paigeWhite;
              background: transparent;
            }
          }
        }
      }
      .aside-menu .nav-tabs .nav-link.active a {
        color: white !important;
        background: #144d49 !important;
        font-weight: bold;
        border-radius: 10px 0 0 10px;
      }
      .title {
        &.nav-item {
          .navigation-menu-item {
            content: "";
            display: none !important;
          }
          .nav-link {
            &.active {
              background: transparent !important;
              &:hover {
                background: transparent !important;
              }
            }
          }
        }
      }

      .talos-add-new-basket {
        font-size: 25px;
        text-align: center;
        background-image: url("/assets/icons/Small_Plus.svg") !important;
        background-repeat: no-repeat !important;
        background-position: 50% !important;
        &.nav-item {
          &:after {
            content: "";
            display: none !important;
          }
          .nav-link {
            &.active {
              background: transparent !important;
              &:hover {
                background: transparent !important;
              }
            }
          }
          a {
            i {
              color: $paigeWhite;
              &:hover {
                color: #61849a;
              }
            }
          }
        }
      }

      .talos-open-document {
        .nav-link {
          &.active {
            background: transparent !important;
            &:hover {
              background: transparent !important;
              font-weight: bold;
            }
          }
          &:hover {
            background: transparent !important;
            font-weight: bold;
          }
        }
        a {
          &:hover {
            background: transparent !important;
          }
          &.active {
            background: transparent !important;
          }
        }
      }
    }

    .talos-recycle-bin {
      background: transparent;
      font-size: 14px;
      color: black !important;
      i {
        &.nav-icon {
          font-size: 18px !important;
          color: black !important;
        }
      }
      .nav-link {
        &:hover {
          // color: $paigeWhite;
          // background: #3a4248 !important;
        }
        &.active {
          color: $paigeWhite;
          background: transparent;
        }
      }
    }
    .talos-thumbnail-tools-container {
      width: 298px;
      .talos-thumbnail-tools {
        width: 155px;
        text-align: center;
        margin-left: 50px;
        li {
          cursor: pointer;
          text-align: center;
        }
        i {
          font-size: 22px;
          padding-top: 20px;
          margin-left: 30px;
        }
      }
    }
    .talos-viewer-tools-container {
      width: calc(100vw - 700px);
      .talos-viewer-tools {
        text-align: center;
        margin: auto;
        width: 440px;
        padding-left: 0px;

        li {
          min-width: auto;
          text-align: center;
          // margin: auto;
          cursor: pointer;
        }

        i {
          font-size: 22px;
          padding-top: 10px;
        }
      }
    }
    .talos-pdf-page-count-container {
      display: flex;
    }

    .r90-clockwise {
      transform: rotate(90deg);
    }

    .talos-pdf-page-count,
    .talos-pdf-zoom-percentage {
      width: 75px;
      // padding: 5px;
      background: #eee;
      border: 0;
      position: relative;
    }

    .talos-viewer-tool-divide {
      margin-left: 50px;
    }

    .workspaces-spinner {
      color: $paigePrimary !important;
    }

    .aside-menu {
      border-top: 1px solid #d2d2d2;
      .talos-operating-tool-tab {
        .nav-link {
          background: $paigeWhite;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }

    .basket-item {
      &.ui-state-hover {
        background-color: rgba(18, 76, 72, 0.3);
        a {
          &.nav-link {
            color: #144d49 !important;
          }
        }
      }
    }

    .sortable {
    }
    .group-caption {
      background: $paigeWhite;
      width: 590px;
      display: block;
      padding: 20px;
      margin: 0 0 15px 0;
    }
    .group-item {
      display: block;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      max-height: 3em;
      line-height: 3em;
      max-width: 494px;
      background: $paigeWhite;
      width: 518px;
      height: 40px;
      padding: 3px;
      margin: 5px;
      color: $paigeBlack;
      position: relative;
      .move {
      }
    }
    .move {
      background: $paigeWhite;
      width: 30px;
      height: 30px;
      float: right;
      color: $paigeBlack;
      border: 1px solid $paigeBlack;
      text-align: center;
      text-transform: uppercase;
      line-height: 30px;
      font-family: Arial, sans-serif;
      cursor: move;
    }
    .movable-placeholder {
      background: #ccc;
      width: 400px;
      height: 100px;
      display: block;
      padding: 20px;
      margin: 0 0 15px 0;
      border-style: dashed;
      border-width: 2px;
      border-color: $paigeBlack;
    }
    .move.ui-sortable-handle {
      position: relative;
      top: -35px;
      right: -35px;
    }
    .ui-draggable-thumbnail-helper {
      background: #ccc;
      text-align: center;
      font-weight: bold;

      &.dragging-multiple {
        box-shadow: 10px 10px 1px 5px $paigePrimary;
      }
    }
    .ui-selected {
      box-shadow: 0px 0px 1px 7px #c69e22;
    }
    .ui-selectable-helper {
      position: absolute;
      z-index: 1000;
      border: 1px dotted black;
    }
  }
}
.sortable-basket-set {
  .group-caption {
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 30px;
    line-height: 1.8em;
    width: 590px;
  }
}
.sortable-basket {
  margin-bottom: 15px !important;
  h4 {
    font-size: 18px;
    font-weight: normal;
    width: 70%;
    float: left;
    max-height: 40px;
    overflow: hidden;
  }
}

body {
  &.modal-open {
    padding-right: 0px !important;
    .modal-backdrop {
      // display: none;
    }
    #addDocumentModal,
    #addBasketBasketSetModal,
    #renameDocumentBasketBasketSetModal,
    #addToOrCreateWorkspaceModal {
      padding-right: 0px !important;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1050;
      width: 90vw;
      height: 90vh;
      overflow: hidden;
      outline: 0;
      margin: auto;
      right: 0;
      bottom: 0;
    }

    #addToOrCreateWorkspaceModal {
      height: 87vh;
    }
    .workspace-pdfs-processed {
      padding: 10px;
      height: 30vh;
    }
    .talos-rename-modal {
      width: 70vw;
      height: 41vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
    }
    .modal-backdrop {
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 0.5;
      position: absolute;
    }
  }
}

.page-workspace {
  #root {
    .sidebar {
      .document-item {
        .document-menu {
          .context-menu {
            a {
              &.dropdown-item {
                &.rename-item,
                &.generate-item,
                &.delete-item,
                &.merge-item {
                  padding-left: 10px !important;
                  color: $paigeBlack !important;
                  &:hover,
                  &:focus {
                    text-decoration: none;
                    background-color: $paigeLightGreen;
                    color: $paigeWhite !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.page-workspace {
  .sidebar {
    .document-menu,
    .basket-menu,
    .basket-set-menu {
      .context-menu {
        position: fixed;
        // top: auto !important;
        left: auto !important;
        a {
          &.dropdown-item {
            &.rename-item,
            &.generate-item,
            &.delete-item,
            &.merge-item {
              padding-left: 10px !important;
              color: $paigeBlack !important;
              &:hover,
              &:focus {
                text-decoration: none;
                background-color: $paigeLightGreen;
                color: $paigeWhite !important;
              }
            }
          }
        }
        .dropdown-item {
          &.font-weight-bold {
            background-color: $paigePrimary;
            color: $paigeWhite !important;
          }
        }
      }
      .fa-plus:before {
        display: none;
      }
    }
  }
  a[name="contextmenu-item-thumbnail-add-blank-page"],
  a[name="contextmenu-item-thumbnail-delete"],
  .fv__ui-cotextmenu-separator {
    // display: none;
  }
}

.page-workspace .sidebar .nav-item.basket-item a {
  color: $paigeGrey !important;
  &:hover {
    .talos-workspace
      .sidebar
      .nav-item.basket-item
      .navigation-item-menu
      .basket-menu
      span {
      color: #144d49 !important;
    }
    color: #144d49 !important;
  }
}
.page-workspace .sidebar .nav-item.basket-item {
  &:hover {
    color: #144d49 !important;
  }
}

a[name="contextmenu-item-thumbnail-add-blank-page"],
a[name="fv--conextmenu-item-search"],
.workspace-document a[name="contextmenu-item-thumbnail-delete"] {
  display: none;
}

.fv__ui-button.active {
  background: rgba(18, 76, 72, 0.4) !important;
  span {
    color: #eee !important;
  }
}

.fv__ui-contextmenu-item {
  &:hover {
    background-color: #144d49 !important;
  }
}

.page-workspace {
  #talos-processing-pdf-card,
  #pdf-being-processed-container,
  #talos-processed-pdf-card {
    border: 0 !important;
  }
  .dashboard-widgets {
    position: relative;
    height: 192px;
    margin-bottom: 39px;
    .talos-add-new-workspace {
      width: 32%;
      display: inline-block;
      // background-color: rgba(233, 127, 117, 0.1) !important;
      background-color: rgba(20, 77, 73, 0.1) !important;
      // border: 5px solid rgba(233, 127, 117, 0.4);
      height: 192px;
      position: absolute;
      top: 0;
      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        height: 20px;
        width: 124px;
      }
    }
    #talos-dropzone-container,
    #talos-mergezone-container {
      width: 100%;
      display: inline-block;
      height: 192px;
      position: absolute;
      // left: 33%;
      #talos-dropzone,
      #talos-mergezone {
        margin-top: 0 !important;
        height: 192px;
        padding-top: 33px;
      }
    }
  }

  .rdt_TableHeadRow {
    letter-spacing: 1.2px;
    color: $paigeGrey;
    text-transform: uppercase;
    font-size: 12px;

    &:hover {
      background-color: transparent !important;
    }

    .rdt_TableCol:first-child {
      padding-left: 0;
    }
  }

  .rdt_TableBody {
    > div {
      div:first-child {
        padding-left: 0;
      }
    }
  }

  #talos-processing-pdf-card,
  #talos-processed-pdf-card {
    margin: 0;
    p {
      margin-top: -2px !important;
    }
    .rdt_Pagination {
      float: left;
      width: 52% !important;
      padding-left: 0 !important;
      left: -4px;
      justify-content: flex-start;
    }
    .pmr-create-workspace-link {
      float: right;
      width: 25px;
      top: -38px;
      span {
        margin-left: 10px;
      }
    }
    .rdt_TableHeader {
      display: none;
    }

    .data-table-extensions {
      padding: 0 !important;
      width: 50%;
      float: right !important;
      position: relative;
      top: 15px;
      margin-top: -15px;
      .data-table-extensions-filter {
        float: right !important;
        .filter-text {
          border-bottom: 1px solid #dedede !important;
          &:hover {
            border-bottom: 1px solid #a5a5a5 !important;
          }
        }
        label {
          filter: saturate(0) contrast(100);
        }
        *::-webkit-input-placeholder {
          /* WebKit browsers */
          color: transparent !important;
          display: none !important;
        }
        *:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: transparent !important;
          display: none !important;
        }
        *::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: transparent !important;
          display: none !important;
        }
        *:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: transparent !important;
          display: none !important;
        }
        *::placeholder {
          color: transparent !important;
          display: none !important;
        }
      }
    }
  }
  #processed-medical-records {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none $paigeBlack;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $paigeWhite;
    }
    &::-webkit-scrollbar-thumb:active {
      background: $paigeBlack;
    }
    &::-webkit-scrollbar-track {
      background: #666666;
      border: 0px none #383838;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
      background: #666666;
    }
    &::-webkit-scrollbar-track:active {
      background: $paigeBlack;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
    &.modal-body {
      height: 78vh;
      overflow: auto;
      margin: 0;
      width: 100%;
      max-width: unset;
      min-width: unset;
    }
  }
  .document-item > a {
    width: 80%;
  }
  // .navigation-item-menu, .navigation-item-menu span {
  //   width: 20px;
  //   height: 35px;
  //   position: relative;
  //   z-index: 100000;
  //   &:hover {
  //     color: #144d49  !important;
  //   }
  // }
  .navigation-item-menu,
  .navigation-item-menu span {
    &:hover {
      color: #144d49 !important;
    }
  }

  .talos-insert-into-basket-modal {
    height: 98vh;
    width: 99vw;
    margin: 5px;
    .modal-content {
    }
    .row {
      margin-bottom: 75px;
    }
    .col {
      text-align: center;
    }
    .row {
      margin-bottom: 85px;
    }
    .basket-thumb-image {
      width: 100%;
      border: 3px solid black;
      margin: 5px;
      cursor: pointer;
      &.thumb-selected {
        border-color: $paigeRed;
        border-width: 5px;
      }
    }
    .basket-thumb-page-number {
      display: block;
      background: $paigePrimary;
      color: $paigeWhite;
      width: 50%;
      margin: 0 5px;
      position: relative;
      top: -8px;
      border: 3px solid black;
      text-align: center;
      width: 100%;
    }
  }
  .fv__thumb-page {
    opacity: 1;
    &.page-in-basket {
      background-color: rgba(18, 76, 72, 0.5) !important;
      .fv__thumb-page-container > .fv__thumb-image {
        opacity: 0.5;
      }
    }
  }
  .fv__thumb-page-index {
    font-weight: 800;
  }
  .fv__thumb-page-hovered {
    border: 3px solid $paigeRed !important;
  }
  .talos-insert-into-basket-modal.modal {
    background: $paigeWhite;
  }
  a[data-name="Pagination"].nav-link {
    &:hover {
      color: $paigeGrey !important;
    }
  }
  a[component-name="contextmenu-item-thumbnail-delete"].fv__ui-hide {
    display: inline-block !important;
  }

  .nav-link {
    display: block; /* or inline-block */
    text-overflow: ellipsis;
    word-wrap: anywhere;
    overflow: hidden;
    // max-height: 65px;
    line-height: 15px;
  }

  .sidebar_settings_icon {
    background: url("/assets/icons/cil-settings.svg");
    background-repeat: no-repeat;
    background-position: 40% 50%;
  }

  .sidebar_nonsearchable_icon {
    background: url("/assets/icons/cil-x.svg");
    background-repeat: no-repeat;
    background-position: 35% 50%;
  }

  .fv__ui-sidebar-panel-title {
    padding-left: 0 !important;
    font-size: 15px !important;
  }
}

.no-document {
  text-align: center;
  margin: auto;
  height: 25px;
  position: absolute;
  top: 0;
  left: 110px;
  right: 0;
  bottom: 0;
  width: 275px;
  font-weight: bold;
}

.page-workspace .progress-bar[aria-valuenow="100"] {
  background-color: $paigePrimary !important;
}

.search-spinner {
  display: inline-block;
  position: relative;
  top: 7px;

  &.hide {
    display: none;
  }
  &.show {
    display: inline-block;
  }
}

.fv__search-panel-result-list-item-active {
  background: rgba(20, 77, 73, 0.5) !important;
  .fv__search-panel-result-list-item-p {
    font-weight: bold;
    color: black;
  }
}

.fv__search-panel-result-list-item-mark {
  background-color: transparent !important;
}

.fv__search-panel-result-list-item-p {
  padding-left: 2px;
}

.nav-link {
  &:hover {
    .fa-archive:before {
      content: "\f187";
      color: $paigeBlack !important;
    }
  }
}

#loadMore {
  padding-top: 8px;
  a {
    color: #222;
    text-decoration: none;
    background-color: transparent;
    border-bottom: 1px dashed #222;
  }
}

.basket-menu, .basket-set-menu{
  .dropdown-menu {
    margin-top:-50px;
  }
}

.basket-menu, .basket-set-menu, .document-menu {
  .dropdown-menu {
    .close {
      position: absolute;
      top: 0;
      right: 4px;
      font-size: 12px;
      color: #fff;
      font-weight: 100;
    }
  }
}

.dropdown-item:hover {
  color: $paigeWhite;
  text-decoration: none;
  background-color: rgba(18, 76, 72, 0.5);
}

.dropdown-item:focus {
  color: #222;
  text-decoration: none;
  background-color: rgba(198, 158, 34, 0.5);
  i {
    color: #222;
  }
}

#root .aps-basket[data-is-created="false"] {
  color: $paigeRed !important;
}

#root .aps-basket[data-length="0"] {
  color: $paigeGrey !important;
}
