.aps-notifications {
  .dropdown-menu {
    width: 230px;
    margin-top: 10px;
    max-height: 500px;
    overflow-y: scroll;
    background: $paigeWhite;

    .remove-notification {
      position: absolute;
      top: 0;
      right: 9px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: $paigeWhite;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: $paigeWhite;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #ddd;
    }

    .aps-notification {
      white-space: normal;
      margin: 3px 0;
      background: $paigeWhite;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &::after {
        content: "i";
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: block;
        text-align: center;
        line-height: 30px;
        font-weight: bold;
        font-size: 20px;
        font-family: serif;
        position: absolute;
        top: 5px;
        left: 5px;
      }

      p {
        padding-left: 15px;
        padding-top: 25px;
        position: relative;
      }

      &.aps-notification-info {
        border: 3px solid $paigeBlue;
        &::after {
          content: "i";
          color: $paigeWhite;
          background: $paigeBlue;
        }
        &:hover {
          &:before {
            color: $paigeWhite;
            background: rgba(35, 125, 198, 0.2) !important;
          }
        }
      }
      &.aps-notification-warning {
        border: 3px solid $paigeSecondary;
        &::after {
          content: "!";
          color: $paigeWhite;
          background: $paigeSecondary;
        }
        &:hover {
          &:before {
            color: $paigeWhite;
            background: rgba(198, 158, 35, 0.2) !important;
          }
        }
      }
      &.aps-notification-error {
        border: 3px solid $paigeRed;
        &::after {
          content: "-";
          font-weight: bold;
          background: $paigeRed;
          font-size: 36px;
          line-height: 22px;
          color: $paigeWhite;
          padding-left: 1px;
        }

        &:hover {
          &:before {
            color: $paigeWhite;
            background: rgba(198, 35, 35, 0.2) !important;
          }
        }
      }
      &.aps-notification-success {
        border: 3px solid $paigePrimary;
        &::after {
          content: "✓";
          font-size: 15px;
          color: $paigeWhite;
          background: $paigePrimary;
        }

        &:hover {
          &:before {
            color: $paigeWhite;
            background: rgba(18, 76, 72, 0.2) !important;
          }
        }
      }
    }
  }

  span {
    .badge {
      position: absolute !important;
      top: -2px !important;
      left: 12px !important;
      font-size: 12px !important;
      text-align: center !important;
      padding-top: 3px !important;
      margin-top: 0 !important;
    }
  }
}

.Toastify__toast {
  &.Toastify__toast--info {
    background: $paigeWhite !important;
    padding: 0;
    margin: 5px;
    color: $paigeBlack;
    width: 100%;
    .Toastify__toast-body {
      background: $paigeWhite !important;
      border: 3px solid $paigeBlue !important;
      margin: 0;
      width: 100%;
      padding: 10px;
      color: $paigeBlack !important;
      font-weight: bold !important;
      padding-left: 30px;

      &::before {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 1px solid $paigeBlue;
        content: "i";
        color: $paigeWhite;
        background: $paigeBlue;
        position: absolute;
        text-align: center;
        line-height: 20px;
        margin-left: -25px;
      }
    }
    .Toastify__progress-bar {
      background: $paigeBlue !important;
    }
  }

  &.Toastify__toast--warning {
    background: $paigeWhite !important;
    padding: 0;
    margin: 5px;
    color: $paigeBlack;
    width: 100%;
    .Toastify__toast-body {
      background: $paigeWhite !important;
      border: 3px solid #c69e22 !important;
      margin: 0;
      width: 100%;
      padding: 10px;
      color: $paigeBlack !important;
      font-weight: bold !important;
      padding-left: 30px;

      &::before {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 1px solid #c69e22;
        content: "!";
        color: $paigeWhite;
        background: #c69e22;
        position: absolute;
        text-align: center;
        line-height: 20px;
        margin-left: -25px;
      }
    }
    .Toastify__progress-bar {
      background: #c69e22 !important;
    }
  }

  &.Toastify__toast--success {
    background: $paigeWhite !important;
    padding: 0;
    margin: 5px;
    color: $paigeBlack;
    width: 100%;
    .Toastify__toast-body {
      background: $paigeWhite !important;
      border: 3px solid $paigePrimary !important;
      margin: 0;
      width: 100%;
      padding: 10px;
      color: $paigeBlack !important;
      font-weight: bold !important;
      padding-left: 30px;

      &::before {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 1px solid $paigePrimary;
        content: "✓";
        color: $paigeWhite;
        background: $paigePrimary;
        position: absolute;
        text-align: center;
        line-height: 20px;
        margin-left: -25px;
      }
    }
    .Toastify__progress-bar {
      background: $paigePrimary !important;
    }
  }
  
  &.Toastify__toast--error {
    background: $paigeWhite !important;
    padding: 0;
    margin: 5px;
    color: $paigeBlack;
    width: 100%;
    .Toastify__toast-body {
      background: $paigeWhite !important;
      border: 3px solid #881717 !important;
      margin: 0;
      width: 100%;
      padding: 10px;
      color: $paigeBlack !important;
      font-weight: bold !important;
      padding-left: 30px;

      &::before {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 1px solid #881717;
        content: "-";
        color: $paigeWhite;
        background: #881717;
        position: absolute;
        text-align: center;
        line-height: 20px;
        margin-left: -25px;
      }
    }
    .Toastify__progress-bar {
      background: #881717 !important;
    }
  }

  .Toastify__close-button {
    position: absolute;
    color: $paigeBlack;
    right: 5px;
    top: 2px;
  }
  .Toastify__progress-bar {
    background: $paigeGrey;
  }
}
