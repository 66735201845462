.page-dashboard {
  .talos-dashboard {
    .talos-workspace-link {
      color: $paigeBlack;
      width: 100%;
      display: block;
      @include transition(all 0.3s ease);
      &:hover {
        text-decoration: none;
        color: $paigeBlack;
        @include material-shadow($z-depth: 2);
        @include transition(all 0.3s ease);
      }
    }

    .talos-pdf-upload {
      width: 200px;
      margin: auto;
    }

    .talos-recent-workspace {
      cursor: pointer;
    }

    #talos-recent-workspaces-sort {
      width: 150px;
      display: inline;
      padding: 0 10px;
      margin: 0 10px 10px 10px;
    }

    label[for="talos-recent-workspaces-sort"] {
      width: 30px;
      display: inline;
    }

    .talos-sort-ascdec {
      width: 10px;
      display: inline;
    }

    #talos-processed-pdf-card {
      border: 0;
      #processed-pdfs {
        header {
          display: none;
        }
        border-collapse: separate;
        border-spacing: 0 15px;
        border: 0;
        tr {
          th,
          td {
            border-bottom: 1px solid #ddd;
            border-left: 0;
            border-right: 0;
          }
        }
        div[role="row"] {
          &:hover {
            background-color: rgba(198, 158, 35, 0.2);
          }
        }
        .processing-progress-label {
          margin: 0;
          padding-bottom: 0px;
          font-size: 16px !important;
          color: $paigeBlack;
          font-weight: 400 !important;
          margin-bottom: 0;
        }
        .data-table-extensions {
          padding: 0 !important;
          width: 50%;
          float: right !important;
          position: relative;
          top: 15px;
          margin-top: -15px;
        }
      }
    }

    #talos-recent-workspaces-card,
    #talos-shared-workspaces-card,
    #talos-processing-pdf-card,
    #workspace-pdfs-processed {
      background-color: $paigeWhite !important;
      header {
        display: none;
      }
      border: 0;
      #talos-recent-workspaces,
      #talos-shared-workspaces,
      #processed-pdfs {
        border-collapse: separate;
        border-spacing: 0 15px;
        border: 0;
        tr {
          th,
          td {
            border-bottom: 1px solid #ddd;
            border-left: 0;
            border-right: 0;
          }
        }
      }
      div[role="row"] {
        &:hover {
          background-color: rgba(198, 158, 35, 0.2);
        }
      }
    }
  }

  .talos-recent-workspace {
    &:hover {
      color: $paigePrimary;
      font-weight: bold;
    }
  }

  .main {
    border-left: 0.5px solid #d2d2d2;
    border-top: 0.5px solid #d2d2d2;
  }

  .table th,
  .table td {
    border-top: none;
    padding: 0rem !important;
  }

  .sidebar {
    background-color: $paigeWhite !important;
    &:before {
      content: ""; /* This is necessary for the pseudo element to work. */
      display: block; /* This will put the pseudo element on its own line. */
      margin: 0 auto; /* This will center the border. */
      width: 80%; /* Change this to whatever width you want. */
      padding-top: 20px; /* This creates some space between the element and the border. */
      border-top: 1px solid #d2d2d2; /* This creates the border. Replace black with whatever color you want. */
    }
  }

  .mt-auto:before {
    content: ""; /* This is necessary for the pseudo element to work. */
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 auto; /* This will center the border. */
    width: 80%; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-top: 1px solid #d2d2d2; /* This creates the border. Replace black with whatever color you want. */
  }

  .recent-workspaces-spinner-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0px;
    left: 0px;
    .recent-workspaces-spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .sidebar_home_icon {
    background-image: url("/assets/icons/cil-home-active.svg");
    background-repeat: no-repeat;
    background-position: 6% 40%;
    &.active {
      background-image: url("/assets/icons/cil-home-active.svg");
    }
  }

  .sidebar_settings_icon {
    background: url("/assets/icons/cil-settings.svg");
    background-repeat: no-repeat;
    background-position: 6% 40%;
  }

  .sidebar_nonsearchable_icon {
    background: url("/assets/icons/cil-x.svg");
    background-repeat: no-repeat;
    background-position: 35% 50%;
  }

  .sidebar_about_icon {
    background: url("/assets/icons/cil-globe-alt.svg");
    background-repeat: no-repeat;
    background-position: 6% 40%;
  }

  .sidebar_help_icon {
    background: url("/assets/icons/cil-school.svg");
    background-repeat: no-repeat;
    background-position: 6% 40%;
  }

  .sidebar_logout_icon {
    background: url("/assets/icons/cil-exit-to-app.svg");
    background-repeat: no-repeat;
    background-position: 6% 75%;
  }

  .pagination_icon {
    background: url("/assets/icons/Pagination.svg") !important;
    background-repeat: no-repeat !important;
    background-position: 6% 50% !important;
    padding-left: 15px !important;
    font-weight: bold !important;
  }

  .pagination_icon .nav-link {
    color: black;
  }

  .or_button {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .search_text {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .proximity_options,
  .search_type {
    border: none;
    background: white;
  }

  .proximity_options_div {
    border-bottom: 0.5px solid #cccccc;
  }

  .search_type_div {
    border: 1px solid #cccccc;
    height: 26px;
    width: 90%;
  }

  html,
  body {
    width: 100%;
    position: relative;
    &.modal-open {
      padding-right: 0px !important;
      overflow-y: visible;
      top: 0;
      bottom: 0;
      .modal-backdrop {
        // display: none;
        position: fixed !important;
        top: 0;
        bottom: 0;
      }
      #newWorkspaceModal,
      #addToOrCreateWorkspaceModal {
        padding-right: 0px !important;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        width: 70vw;
        height: 72vh;
        display: block;
        overflow: hidden;
        outline: 0;
        margin: auto;
        right: 0;
        bottom: 0;
        min-height: 660px;
        opacity: 1;

        .login-spinner-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #ccc;
          opacity: 0.9;
          .login-spinner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            &.text-info {
              color: #222 !important;
            }
          }
          p {
            color: #222 !important;

            position: absolute;
            top: 80px;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: 10px;
            width: 366px;
            font-weight: bold;
            font-size: 22px;
          }
        }
      }

      .modal-backdrop {
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
      }
    }
  }

  .app-header {
    .dropdown-item:hover,
    .dropdown-item:focus,
    .dropdown-item:active {
      background-color: rgba(198, 158, 35, 0.2);
    }
  }
  .rdt_TableHeadRow {
    letter-spacing: 1.2px;
    color: $paigeGrey;
    text-transform: uppercase;
    font-size: 12px;

    &:hover {
      background-color: transparent !important;
    }

    .rdt_TableCol:first-child {
      padding-left: 0;
    }
  }

  .rdt_TableBody {
    > div {
      div:first-child {
        padding-left: 0;
      }
    }
  }
  .rdt_TableHeader {
    display: none !important;
  }
  #talos-processing-pdf-card {
    margin: 0;
    p {
      margin-top: -2px !important;
    }
    .rdt_Pagination {
      float: left;
      width: 52% !important;
      padding-left: 0 !important;
      left: -4px;
      justify-content: flex-start;
    }
    .pmr-create-workspace-link {
      float: right;
      width: 266px;
      padding: 5px;
      border-radius: 5px;
      top: 10px;
      padding-left: 15px;
      padding-right: 15px;
      &:hover {
        background-color: #c69e22;
      }
      span {
        margin-left: 10px;
      }
      svg {
        fill: $paigeWhite;
        position: relative;
        top: -1px;
      }
    }

    .data-table-extensions {
      padding: 0 !important;
      width: 50%;
      float: right !important;
      position: relative;
      top: 15px;
      margin-top: -15px;
      .data-table-extensions-filter {
        float: right !important;
        .filter-text {
          border-bottom: 1px solid #dedede !important;
          &:hover {
            border-bottom: 1px solid #a5a5a5 !important;
          }
        }
        label {
          filter: saturate(0) contrast(100);
        }
        *::-webkit-input-placeholder {
          /* WebKit browsers */
          color: transparent !important;
          display: none !important;
        }
        *:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: transparent !important;
          display: none !important;
        }
        *::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: transparent !important;
          display: none !important;
        }
        *:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: transparent !important;
          display: none !important;
        }
        *::placeholder {
          color: transparent !important;
          display: none !important;
        }
      }
    }
  }

  #pdf-being-processed-container,
  #processed-pdfs,
  #talos-recent-workspaces-card,
  #talos-shared-workspaces-card {
    border-bottom: 1px rgba(0, 0, 0, 0.12) solid !important;
    margin-bottom: 50px !important;
  }

  #processed-pdfs,
  #talos-recent-workspaces-card,
  #talos-shared-workspaces-card {
    padding-bottom: 30px !important;
  }

  #talos-recent-workspaces-card,
  #talos-shared-workspaces-card {
    .rdt_Pagination {
      float: left;

      padding-left: 0 !important;
      position: relative;
      left: -5px;
      justify-content: flex-start;
    }
  }

  .data-table-extensions {
    padding: 0 !important;
    .data-table-extensions-filter {
      float: right !important;
      .filter-text {
        border-bottom: 1px solid #dedede !important;
        &:hover {
          border-bottom: 1px solid #a5a5a5 !important;
        }
      }
      label {
        filter: saturate(0) contrast(100);
      }
      *::-webkit-input-placeholder {
        /* WebKit browsers */
        color: transparent !important;
        display: none !important;
      }
      *:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent !important;
        display: none !important;
      }
      *::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent !important;
        display: none !important;
      }
      *:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent !important;
        display: none !important;
      }
      *::placeholder {
        color: transparent !important;
        display: none !important;
      }
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $paigePrimary !important;
  }
  .MuiCheckbox-root {
    color: $paigePrimary !important;
  }

  .dz-hidden-input {
    z-index: 10000;
  }

  .progress-bar[aria-valuenow="100"] {
    background-color: $paigePrimary !important;
  }

  .progress-bar.error {
    background-color: $paigeRed !important;
  }

  .progress-bar.warning {
    background-color: #c69e22 !important;
  }

  .aside-menu-lg-show {
    display: none;
  }
}

.progress-bar.error {
  background-color: $paigeRed !important;
}

.progress-bar.warning {
  background-color: #c69e22 !important;
}

#updateWorkspaceDetails label {
  display: inline-block;
  width: 14em;
  text-align: right;
  padding-right: 1em;
}

#updateMessage {
  text-align: center;
}
#updateWorkspaceDetails input {
  display: inline-block;
}

.bootbox-body2 {
  text-align: center;
}

.merge-input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  display: none;
}

.highlight {
  background-color: rgb(188, 185, 236);
}

.data-table-extensions > .data-table-extensions-filter > .filter-text {
  border: 0;
  border-bottom: 1px solid #f5f5f5;
  outline: none;
  padding: 4px;
  margin-left: 4px;
  background-color: transparent;
}

.data-table-extensions > .data-table-extensions-filter > .icon {
  float: left;
  display: block;
  width: 20px;
  height: 24px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAB80lEQVQ4T73Uv2tTURQH8O85972kxpqWNG2gOKhb21X8hXYQxCEKLp3cXNw6OIm0zTWWVjo4Cv4BIlIEF4c6FEQQi4uLZNTFYvNDiwWN9713jrxKQZOXBNLiXd89H77n3Hcv4YAXHbCH/wuOWh3kKJhSEDvjVbYtbffqKDFhrqRZ1ui+IrrB4PQfRCIieorAv1Vdoq1OcBsYY4jCV6Q0aAxuS9O8TGUQhRJeUGBZoTmQf65+lzaT0DYwvxA+1AiXBsSc+bxMjb+Lxq1mnITrpFKr3Utf7QnGMxNxdQNzvVr2niUVjFh3ioU2WMITW4uHPrbu+SdhYd6dFqK32vSy9RXaSZ6T0uiCawDmZq3srXYFx+aDs0p447N3eNPSj06Dz881a2Bvtl72nnQFh60O++LqBC5Wy/5aEpizvyaN8AcRmWwspitdwfjjWMk9VqUJn73zbSln1IxMuBcMztTK/nTPQ9kF72hBvGADoC/KOtuw/juANE5GggcMvkyq6xnjFz9ZavZMGG/Il3ScxD0C8RVAvqloRGzyAF6TaqBEFwWydoRT11rRrne5MPfzuLB/UgFPJXofz+yY1YEdcc/jpEloX49DK9qopIpYpSjuri8wLtxDAUwHnDr63dLXfYG7hzGjJjuFoT1s/2DCf9N3y51u0W9RndcVBJTNUgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: 0;
}

.rdt_TableHeader {
  display: none !important;
}

.aps-userinits {
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  line-height: 28px;
  background: $paigeWhite;
  text-align: center;
  background-color: $paigeWhite !important;
  font-weight: bold !important;
  margin: 0 auto;
  padding-right: 0 !important;
  display: block;
  font-size: 13px !important;
  border: 2px solid $paigeBlack;
  color: $paigeBlack !important;
  opacity: 0.9;
}
