#debug-toolbar-environment-selector-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 35px;
  z-index: 2000;
  background: #ccc;
  padding: 6px;
  #debug-toolbar-environment-selector {
    textarea {
      height: 25px;
    }
    span {
      position: relative;
      top: -8px;
    }
    label, select {
        position: relative;
        padding: 0 5px;
    }
  }
}
