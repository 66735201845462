.modal-full {
    position: fixed;
    z-index: 2000;
    top: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    max-width: none;
    background-color: rgba(255,255,255,0.93);
}

.modal-full .modal-content {
    min-height: 100vh;
    background: transparent;
}

.talos-new-workspace-modal.modal, .talos-add-document-modal.modal, .talos-insert-into-basket-modal.modal {
    display: block!important;
    opacity: 1!important;
}