.aps-userinits {
    border-radius: 50%;
    width: 30px!important;
    height: 30px!important;
    line-height: 27px;
    background: $paigeWhite;
    text-align: center;
    background-color: $paigeWhite!important;
    font-weight: bold!important;
    margin: 0 auto;
    padding-right: 0!important;
    display: block;
    font-size: 11px!important;
    border: 2px solid $paigeBlack;
    color:$paigeBlack!important;
    opacity:0.9
  }