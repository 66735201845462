.foxit-loaded {
  .main {
    .container-fluid {
      padding: 0 0px;
    }
  }

  .breadcrumb {
    margin: 0;
  }

  .app-body {
    overflow: hidden;
  }

  body {
    overflow: hidden;
  }

  #pdf-ui {
    height: calc(100vh - 88px);

    .fv__ui-grouplist-item > :first-child {
      margin-left: 0px;
    }

    .fv__ui-thumbnail-container {
      .fv__thumb-image-container {
        .fv__thumb-page {
          background: #ccc;
          &.selected {
            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: 4px inset blue;
            }
          }
        }
      }
    }

    .fv__ui-grouplist.horizontal > .fv__ui-grouplist-item:last-child {
      margin-right: 0;
      position: absolute;
      right: 0;
    }

    /* .sidebar {
          right: 0;
      } */

    /* .main, .app-footer {
          margin-left: 0!important;
          margin-right: 150px!important;
      } */

    .fv__ui-sidebar-ctls {
      display: none !important;
    }

    .fv__ui-toolbar {
      background: #fafafa;
    }

    .main > nav {
      display: none;
    }

    .custom-icon {
      padding: 5px;
      width: 40px;
      height: 40px;
      font-size: 25px;
      .fv__ui-dropdown-toggler {
        position: relative;
        top: -25px;
        left: 15px;
      }
    }
  }

  .aside-menu {
    .nav {
      &.nav-tabs {
        position: fixed;
        top: 165px;
        right: 0px;
        z-index: 999999;
        display: block;
        transition: margin-left 0.25s, margin-right 0.25s, width 0.25s,
          flex 0.25s;
        background: transparent;
        .nav-item {
          border: 1px solid #144d49 !important;
          border-radius: 10px 0 0 10px;
        }
      }
    }
  }
  .nav-tabs .nav-item {
    margin-bottom: 15px;
  }

  &.aside-menu-lg-show {
    .aside-menu {
      .nav {
        &.nav-tabs {
          margin-right: 249px;
          background: transparent;
          border: none;
          box-shadow: none !important;
          transition: margin-left 0.25s, margin-right 0.25s, width 0.25s,
            flex 0.25s;
        }
      }
    }
  }

  .fv__ui-pdfviewer {
    margin-right: 70px;
  }

  .aside-menu .nav.nav-tabs {
    box-shadow: none !important;
  }
  .nav-tabs {
    border-bottom: none !important;
  }
  .fv__ui-thumbnail-container {
    .fv__thumb-image-container {
      .fv__thumb-page.fv__active {
        background: #eee;
        border: 3px solid $paigeBlack;
      }
    }
  }
}

.sidebar-fixed {
  .app-header {
    + .app-body {
      .sidebar {
        height: calc(100vh - 88px);
      }
    }
  }
}

.fv__search-panel-form-submit {
  &.fa-search {
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    border: 0;
    background: #4dbd74;
    color: $paigeWhite;
  }
}

.talos-legend {
  &.btn-outline {
    border-width: 3px;
    &:hover {
      background: $paigeWhite;
      color: $paigeBlack;
    }

    &.talos-disease {
      color: $paigeWhite;
      background-color: #ff8fbe;
      border: 1px solid #ff8fbe;
    }

    &.talos-drug {
      color: $paigeWhite;
      background-color: #2ecc70;
      border-color: #2ecc70;
    }

    &.talos-body-condition {
      color: $paigeWhite;
      border: 1px solid #fda91c;
      background-color: #fda91c;
    }

    &.talos-procedure {
      color: $paigeWhite;
      border: 1px solid #58c4fe;
      background-color: #58c4fe;
    }

    &.talos-blood-test {
      color: $paigeWhite;
      border: 1px solid #ff8fbe;
      background-color: #ff8fbe;
    }

    &.talos-gp-computerised {
      color: $paigeWhite;
      border: 1px solid #eb88fe;
      background-color: #eb88fe;
    }

    &.talos-consent-form {
      color: $paigeWhite;
      border: 1px solid #1edd41;
      background-color: #1edd41;
    }

    &.talos-intro-page,
    &.talos-first-page {
      color: $paigeWhite;
      border: 1px solid #fda91c;
      background-color: #fda91c;
    }

    &.talos-consultation {
      color: $paigeWhite;
      border: 1px solid #58c4fe;
      background-color: #58c4fe;
    }

    &.talos-other {
      color: $paigeWhite;
      border: 1px solid #e9f33c;
      background-color: #e9f33c;
    }
    &.talos-duplicates {
      color: $paigeWhite;
      border: 1px solid #e9f33c;
      background-color: #e9f33c;
    }
    &.talos-medication {
      color: $paigeWhite;
      border: 1px solid #1edd41;
      background-color: #1edd41;
    }
    &.talos-blank {
      color: $paigeWhite;
      border: 1px solid #79ecf1;
      background-color: #79ecf1;
    }
  }
}

.fv__thumb-image-container {
  .fv__thumb-page {
    &.talos-blood-test {
      border: 5px solid #ff8fbe !important;
    }

    &.talos-gp-computerised {
      border: 5px solid #eb88fe !important;
    }

    &.talos-consent-form {
      border: 5px solid #1edd41 !important;
    }

    &.talos-intro-page {
      border: 5px solid #f2c511 !important;
    }

    &.talos-consultation {
      border: 5px solid #58c4fe !important ;
    }

    &.talos-other {
      border: 5px solid #e9f33c !important ;
    }

    &.talos-disease {
      border: 5px solid #ff8fbe !important ;
    }

    &.talos-medication {
      border: 5px solid #1edd41 !important ;
    }

    &.talos-condition {
      border: 5px solid #fda91c !important ;
    }

    &.talos-procedure {
      border: 5px solid #58c4fe !important ;
    }

    &.talos-blank {
      border: 5px solid #79ecf1 !important ;
    }

    &.talos-intro,
    &.talos-first-page {
      border: 5px solid #fda91c !important ;
    }

    &.talos-clustering-0 {
      border: 5px solid #ff8fbe !important ;
    }
    &.talos-clustering-1 {
      border: 5px solid #1edd41 !important ;
    }
    &.talos-clustering-2 {
      border: 5px solid #fda91c !important ;
    }
    &.talos-clustering-3 {
      border: 5px solid #58c4fe !important ;
    }
    &.talos-clustering-4 {
      border: 5px solid #eb88fe !important ;
    }
    &.talos-clustering-5 {
      border: 5px solid #79ecf1 !important ;
    }
    &.talos-clustering-6 {
      border: 5px solid #e9f33c !important ;
    }
    &.talos-clustering-7 {
      border: 5px solid #1cfdb2 !important ;
    }
    &.talos-clustering-8 {
      border: 5px solid #d7e460 !important ;
    }
    &.talos-clustering-9 {
      border: 5px solid #5b58fe !important ;
    }
    &.talos-clustering-10 {
      border: 5px solid #fecb88 !important ;
    }
    &.talos-clustering-11 {
      border: 5px solid #1eddd4 !important ;
    }
    &.talos-clustering-12 {
      border: 5px solid #e1a1fe !important ;
    }

    &.talos-blood-test,
    &.talos-gp-computerised,
    &.talos-consent-form,
    &.talos-intro-page,
    &.talos-consultation,
    &.talos-disease,
    &.talos-medication,
    &.talos-condition,
    &.talos-procedure,
    &.talos-blank,
    &.talos-first-page,
    &.talos-intro,
    &.talos-clustering-0,
    &.talos-clustering-1,
    &.talos-clustering-2,
    &.talos-clustering-3,
    &.talos-clustering-4,
    &.talos-clustering-5,
    &.talos-clustering-6,
    &.talos-clustering-7,
    &.talos-clustering-8,
    &.talos-clustering-9,
    &.talos-clustering-10,
    &.talos-clustering-11,
    &.talos-clustering-12 {
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

#mer-demo {
  width: 100vw;
  height: 100vh;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  .fa.fa-close {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    cursor: pointer;
    color: $paigeWhite;
    font-size: 30px;
  }
  .modal-dialog {
    margin: 0;
    max-width: initial;
    width: 100%;
    height: 100%;
    background: transparent;
    .modal-content {
      margin: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      img,
      .add-to-basket form,
      .new-pagination-set form {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
      }
      .add-to-basket form,
      .new-pagination-set form {
        width: 400px;
        height: 200px;
        background: $paigeWhite;
        text-align: center;
        padding: 60px;
      }
      .new-pagination-set form {
        height: 300px;
        text-align: left;
        padding: 40px 50px;
      }
      .charts {
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
}

.talos-download-pagination {
  cursor: pointer;
}
